import React from 'react';
import {withRouter} from 'next/router';
import {CSSTransition} from 'react-transition-group';
import {Drawer, Search} from './SubNav';
import {primary, BREAKPOINT} from '../../../lib/styles';
import {zIndex} from 'aac-components/utils/helpers';

const MAX_WIDTH = '1400px';
const LOGO_WIDTH = '150px';
const HEIGHT = '325px';

const DesktopSubNav = (props) => {
    const {activeSubName = '', menu = []} = props;

    const activeSubLinks = menu.find((item) => {
        const {title = ''} = item;
        let selectedTitle = title && title.replace(/\ /g, '_').toLowerCase();
        selectedTitle = selectedTitle.replace(/&/g, 'and');

        return activeSubName === selectedTitle || activeSubName === 'search';
    });

    return (
        <CSSTransition
            in={!!activeSubLinks}
            classNames={'slide'}
            timeout={0}
            appear={true}
            unmountOnExit>
            <div className="sub-nav" key={`${activeSubName}`}>
                {activeSubName === 'search' ? (
                    <Search {...props} />
                ) : (
                    <Drawer
                        activeSubLinks={activeSubLinks}
                        navKey={activeSubName}
                        {...props}
                    />
                )}
                <style jsx>
                    {`
                        .slide-enter {
                            opacity: 0;
                            transform: translateY(-25%);
                            top: 0;
                        }
                        .slide-enter-done {
                            opacity: 1;
                            transform: translateY(0px);
                            transition: all 200ms ease;
                        }
                        .slide-exit {
                            transform: translateY(0px);
                        }
                        .slide-exit-active {
                            transform: translateY(-25%);
                            transition: transform 200ms ease;
                        }

                        nav {
                            max-width: ${MAX_WIDTH};
                            display: flex;
                            margin: 0 auto;
                        }
                        .sub-nav {
                            display: none;
                        }

                        @media screen and (min-width: ${BREAKPOINT}) {
                            .sub-nav {
                                display: block;
                                position: relative;
                                top: 100%;
                                left: 0;
                                z-index: ${zIndex('subNavigation')};
                                background-color: #fff;
                                background-image: linear-gradient(
                                    to right,
                                    #fff,
                                    #fafafa
                                );
                                margin: 0 auto;
                                max-width: 100% !important;
                                width: 100% !important;
                            }
                        }

                        .treatment-centers-menu {
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: row wrap;
                            flex: 3;
                        }
                        .treatment-center-state {
                            flex: 1 0 calc(33.33% - 20px);
                            padding: 0 60px;
                            max-height: fit-content;
                            margin-bottom: 15px;
                        }
                        .treatment-center-state h3 {
                            font-size: 13px;
                            font-weight: bold;
                            margin-bottom: 0;
                            margin-top: 5px;
                        }
                        .treatment-center-city {
                            font-size: 13px;
                            margin: 10px 0 0 0;
                        }
                        .treatment-center-link {
                            height: 20px;
                            font-size: 13px;
                            font-weight: bold;
                            line-height: 20px;
                            padding-left: 8px;
                            color: var(--primary-500);
                            display: inline-block;
                        }
                        .treatment-center-state hr {
                            margin: 5px 0;
                        }
                        li {
                            list-style: none;
                        }
                    `}
                </style>
            </div>
        </CSSTransition>
    );
};

export default withRouter(DesktopSubNav);
